import React from "react"
import Layout from "../components/Layout"

const product = () => {
  return (
    <Layout
      title="Frequently asked questions"
      description="On this page, we gathered the most common questions our customers ask."
      contentType="website"
    >
      <section className="default-section">
        <h3>FAQ page is under construction. Please check a bit later</h3>
      </section>
    </Layout>
  )
}

export default product
